import React from 'react';
import LandingPagePreview from './LandingPagePreview';

function App() {
  return (
    <div className="App">
      <LandingPagePreview />
    </div>
  );
}

export default App;