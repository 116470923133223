import React, { useState } from 'react'
import { Send, Phone, Mail, MapPin } from 'lucide-react'

export default function ContactSection() {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: ''
  })
  const [submitStatus, setSubmitStatus] = useState(null)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormState(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitStatus('submitting')
    try {
      const response = await fetch('http://localhost:3001/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formState),
      })
      if (response.ok) {
        setSubmitStatus('success')
        setFormState({ name: '', email: '', message: '' })
      } else {
        setSubmitStatus('error')
      }
    } catch (error) {
      console.error('Error submitting form:', error)
      setSubmitStatus('error')
    }
  }

  return (
    <section id="contact" className="py-16 bg-gradient-to-b from-[#0d2b38] to-[#1a202c] rounded-xl">
      <div className="max-w-4xl mx-auto px-6">
        <h3 className="text-4xl font-bold mb-6 text-[#67e8f9] text-center">
          Get in Touch
        </h3>
        <p className="text-gray-300 text-xl mb-12 max-w-2xl mx-auto text-center">
          Curious about how Dhvani Lab can transform your practice?
          We're here to answer your questions.
        </p>
        
        <div className="grid md:grid-cols-2 gap-12">
          <div className="space-y-8">
            <h4 className="text-2xl font-semibold text-[#67e8f9] mb-4">Contact Information</h4>
            <div className="flex items-center space-x-4">
              <Phone className="w-6 h-6 text-[#67e8f9]" />
              <span className="text-gray-300">+1 (555) 123-4567</span>
            </div>
            <div className="flex items-center space-x-4">
              <Mail className="w-6 h-6 text-[#67e8f9]" />
              <span className="text-gray-300">contact@dhvanilab.com</span>
            </div>
            <div className="flex items-center space-x-4">
              <MapPin className="w-6 h-6 text-[#67e8f9]" />
              <span className="text-gray-300">123 AI Avenue, Tech City, TC 12345</span>
            </div>
          </div>
          
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formState.name}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 bg-[#1a202c] text-gray-300 placeholder-gray-500 border border-[#2d3748] rounded-md focus:outline-none focus:ring-2 focus:ring-[#67e8f9] focus:border-transparent"
              />
            </div>
            <div>
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formState.email}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 bg-[#1a202c] text-gray-300 placeholder-gray-500 border border-[#2d3748] rounded-md focus:outline-none focus:ring-2 focus:ring-[#67e8f9] focus:border-transparent"
              />
            </div>
            <div>
              <textarea
                name="message"
                placeholder="Your Message"
                value={formState.message}
                onChange={handleInputChange}
                required
                rows={4}
                className="w-full px-3 py-2 bg-[#1a202c] text-gray-300 placeholder-gray-500 border border-[#2d3748] rounded-md focus:outline-none focus:ring-2 focus:ring-[#67e8f9] focus:border-transparent"
              />
            </div>
            <button 
              type="submit" 
              className="w-full px-4 py-2 bg-transparent hover:bg-[#67e8f9] text-[#67e8f9] hover:text-[#1a202c] border border-[#67e8f9] hover:border-transparent rounded-full transition-colors duration-300 flex items-center justify-center"
              disabled={submitStatus === 'submitting'}
            >
              {submitStatus === 'submitting' ? 'Sending...' : 'Contact Us'} <Send className="ml-2 h-4 w-4" />
            </button>
            
            {submitStatus === 'success' && (
              <p className="text-green-400">Thank you for your message. We will get back to you soon!</p>
            )}
            {submitStatus === 'error' && (
              <p className="text-red-400">An error occurred. Please try again later.</p>
            )}
          </form>
        </div>
      </div>
    </section>
  )
}