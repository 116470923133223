import React, { useEffect } from 'react';
import { Smartphone, Calendar, MessageCircle } from 'lucide-react';
import ContactSection from './ContactSection';

const Button = ({ children, className, onClick }) => (
  <button
    className={`px-4 py-2 rounded-full font-semibold ${className}`}
    onClick={onClick}
  >
    {children}
  </button>
);

const Feature = ({ icon: Icon, title, description }) => (
  <div className="bg-gray-800 bg-opacity-50 p-6 rounded-xl shadow-lg space-y-3">
    <Icon className="w-8 h-8 text-cyan-300 mx-auto" />
    <h4 className="text-lg font-semibold text-center text-cyan-300">{title}</h4>
    <p className="text-gray-300 text-center text-sm">{description}</p>
  </div>
);

export default function LandingPagePreview() {
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      document.body.style.setProperty('--scroll', scrollY + 'px');
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="content-wrapper">
      <header className="sticky top-0 z-10 bg-gray-900 bg-opacity-70 backdrop-blur-sm">
        <div className="max-w-6xl mx-auto px-6 py-4">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-cyan-300">Dhvani Lab</h1>
            <nav>
              <ul className="flex space-x-6">
                {['Features', 'Join', 'Contact'].map((item) => (
                  <li key={item}>
                    <a
                      href={`#${item.toLowerCase()}`}
                      className="text-gray-300 hover:text-cyan-300 transition-colors text-sm font-medium"
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToSection(item.toLowerCase());
                      }}
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <main className="flex-grow">
        <div className="max-w-6xl mx-auto px-6 py-12 space-y-24">
          <section className="flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 space-y-6">
              <h2 className="text-4xl font-bold leading-tight text-cyan-300">
                Simplifying Patient Appointments for Doctors
              </h2>
              <p className="text-xl text-gray-300 leading-relaxed">
                Experience the future of scheduling with our intelligent voice agents.
              </p>
              <Button
                className="bg-cyan-500 hover:bg-cyan-600 text-gray-900"
                onClick={() => scrollToSection('features')}
              >
                Learn More
              </Button>
            </div>
            <div className="md:w-1/2 mt-8 md:mt-0">
              <div className="w-full aspect-[16/9] bg-cyan-800 rounded-xl shadow-lg overflow-hidden">
                <img src="/Design2.png" alt="AI-powered scheduling" className="w-full h-full object-cover object-center" />
              </div>
            </div>
          </section>

          <section id="features">
            <h3 className="text-3xl font-bold mb-12 text-center text-cyan-300">
              Smart Solutions for Modern Practices
            </h3>
            <div className="grid md:grid-cols-3 gap-8">
              <Feature
                icon={Smartphone}
                title="AI-Driven Voice Agents"
                description="Intelligent voice agents for seamless scheduling."
              />
              <Feature
                icon={Calendar}
                title="Efficient Calendar Management"
                description="Optimize your time with smart calendar integration."
              />
              <Feature
                icon={MessageCircle}
                title="Seamless Communication"
                description="Improve patient interactions with smart tools."
              />
            </div>
          </section>

          <section id="join" className="bg-gray-800 bg-opacity-50 rounded-xl p-8">
            <div className="max-w-2xl mx-auto text-center space-y-6">
              <h3 className="text-3xl font-bold text-cyan-300">
                Join the Waiting List
              </h3>
              <p className="text-gray-300 text-lg">
                Be among the first to experience Dhvani Lab's innovative solutions.
              </p>
              <form className="flex flex-col sm:flex-row gap-4 max-w-md mx-auto" onSubmit={(e) => e.preventDefault()}>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="flex-grow bg-gray-700 text-gray-100 placeholder-gray-400 border-gray-600 focus:ring-2 focus:ring-cyan-500 px-4 py-2 rounded-full"
                />
                <Button
                  className="bg-cyan-500 hover:bg-cyan-600 text-gray-900"
                  onClick={() => alert('Thank you for joining our waiting list!')}
                >
                  Sign Up
                </Button>
              </form>
            </div>
          </section>

          <ContactSection />
        </div>
      </main>

      <footer className="bg-gray-800 bg-opacity-70 py-8">
        <div className="max-w-6xl mx-auto px-6 text-center text-gray-400">
          <p>&copy; 2024 Dhvani Lab. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}